

















































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';
import { namespace } from 'vuex-class';
import ContactDetailsDialog from '../../contact/components/ContactDetailsDialog.vue';
import ContactPersonRow from '../../contact/components/ContactPersonRow.vue';
import { ContactPerson } from '../types';
import { ReceiverType } from '../../correspondence/types';
import { GET_CONTACT_PERSONS_BY_CONTACT } from '@/modules/conservatorshipToContact/store';
import EditContactPersonDialog from '@/modules/contact/components/EditContactPersonDialog.vue';

const ConservatorshipToContact = namespace('conservatorshipToContact');

@Component({
  components: {
    EditContactPersonDialog,
    ContactDetailsDialog,
    ContactPersonRow
  }
})
export default class ContactPersonsDialog extends Vue {
  @Prop({ type: String, required: true }) conservatorship!: string;
  @Prop({ type: String, required: true }) contact!: string;
  @Prop({ type: String }) icon?: string;
  @Prop({ type: Array, default: () => [] }) receiverTypes!: ReceiverType[];

  @ConservatorshipToContact.Getter(GET_CONTACT_PERSONS_BY_CONTACT) getByContact!: (conservatorship_id: string, contact_id: string) => ContactPerson[];

  contactDetails: ContactPerson | null = null;
  updateContactPerson: ContactPerson | null = null;
  dialog: boolean = false;

  get contactPersons() {
    return this.getByContact(this.conservatorship, this.contact);
  }

  generateLetterUrl(contactPerson: ContactPerson): Location | undefined {
    if (this.receiverTypes.length === 0) {
      return;
    }

    return {
      name: 'correspondence-letter-builder',
      query: {
        conservatorship: contactPerson.conservatorshipId,
        contact: contactPerson.contactId,
        receiverType: this.receiverTypes[0],
        contactPerson: contactPerson.id,
        referrer: this.$route.fullPath
      }
    };
  }

  generateEmailUrl(contactPerson: ContactPerson) {
    if (this.receiverTypes.length === 0 || !contactPerson.email) {
      return;
    }

    return {
      name: 'correspondence-email-builder',
      query: {
        conservatorship: contactPerson.conservatorshipId,
        contact: contactPerson.contactId,
        receiverTypes: this.receiverTypes,
        contactPerson: contactPerson.id,
        referrer: this.$route.fullPath
      }
    };
  }

  get editLink() {
    return {
      name: 'conservatorship-manage-contact-persons',
      params: { contact: this.contact, conservatorship: this.conservatorship },
      query: { active: this.$route.query['active'] }
    };
  }
}
